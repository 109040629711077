import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
    

const aboutHtml = ({data,location}) => {
    
  return(   
	<Layout>
	   <Seo
          pagetitle="ABOUT"
		  pagedesc="MAZINはデータ分析、システム開発、センサ/PLCなどからのデータ収集に強みを持つ企業です。"
		  pagepath={location.pathname}
        />
      <div className="container-fluid about">
        <div className="row">
          <div className="top-view">
            <div className="top-img bg-img_cover" />
            <ul className="breadcrumb">
              <li><Link to={`/`}>TOP</Link></li>
              <li>ABOUT</li>
            </ul>{/*.breadcrumb*/}
            <div className="h1_wrap">
              <h1>会社概要</h1>
            </div>{/*.h1_wrap*/}
            <a href="#scroll" id="scroll-button">
              <div className="scroll-down">
                <div>SCROLL</div><div />
              </div>
            </a>
          </div>{/*.top-view*/}
          <div id="scroll" className="contents">
            <div className="second-top about-mazin bg-img_cover black-filter">
              <div className="contents-title contents-title_white">MAZIN <br className="d-md-none" />IS</div>
              <div className="contents-box">
                <h3 className="kakko underline">強い工場を造る。<span /><span /><span /><span /></h3><div />
                <div className="contents-comment">
                  <div className="comment-br">
                    現場と一緒に、MAZIN は進む。
                  </div>
                  <div className="comment-br">
                    強い工場が多くの人の根となり、<br />
                    そこには街が芽吹き、<br />
                    未来へと育っていく。
                  </div>
                  <div className="comment-br">
                    MAZIN は、<br />
                    そんな社会を底支えするような、<br />
                    「強い工場を造る。」<br />
                    製造現場に特化し伴走するテクノロジー企業です。
                  </div>
                </div>{/*.contents-comment*/}
              </div>{/*.contents-box*/}
            </div>{/*.second-top*/}
            <div className="dream">
              <div className="row">
                <div className="col-md-6 order-md-2">
                      <div className="contents-box">
                        <h3 className="underline">いつか自分の工場を</h3><div />
                        <div className="contents-comment">
                          <div className="comment-br">
                            MAZIN は、自動車や飛行機などの大きくてかっこいい機械が、子どもながらに大好きです。<br />
                            そして、それを造れる工場に憧れを持ち、
                          </div>
                          <div className="comment-br">
                            「いつか自分の工場を持ちたい。」<br />
                            という夢を持ち創業しました。
                          </div>
                          <div className="comment-br">
                            創業後、様々な工場へ実際に足を運び、AI/IoTのシステムを導入する仕事を通じて、テクノロジーによる工場改善の可能性、社会を底支えする工場の重要性に気づき、自らの技術を活用して工場改善を担いたいという使命感を持つようになりました。
                          </div>
                          <div className="comment-br">
                            そんな想いから現在、「強い工場を造る。」<br />
                            をミッションに掲げて、多くの工場の改善に繋がるような製品の開発・販売を行っています。
                          </div>
                        </div>{/*.contents-comment*/}
                      </div>{/*.contents-box*/}
                </div>
                <div className="col-md-6 order-md-1">
                      <div className="dream-img bg-img_cover" />
                </div>
              </div>
            </div>{/*.third-top*/}
            <div className="value">
              <div className="contents-box">
                <div className="contents-title">VALUE</div>
                <h3 className="underline">三つの行動</h3><div />
                <div className="contents-comment">
                  「強い工場を造る。」<br />
                  このミッションを達成するために、MAZIN は常に三つの行動を起こし続けます。
                  <div className="value-list">
                    <div>
                      <h4>一：泥臭くあれ</h4>
                      <div className="text">易きに流されず、芯を持って行動を起こす。</div>
                    </div>
                    <div>
                      <h4>二：無垢であれ</h4>
                      <div className="text">自ら考え、積極的に行動を起こす。</div>
                    </div>
                    <div>
                      <h4>三：背を預けろ</h4>
                      <div className="text">仲間を信じ、信頼される行動を起こす。</div>
                    </div>
                  </div>{/*.value-list*/}
                </div>{/*.contents-comment*/}
              </div>{/*.contents-box*/}
            </div>{/*.value*/}
            <div className="member">
              <div className="contents-title">MEMBER</div>
              <div className="contents-box">
      {/*<Member />*/}
                <div className="member-list">
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_1">
                                <div className="member-photo bg-img_cover" />
                                <div className="member-lead">
                                    <div className="member-name">角屋 貴則</div>
                                    <div className="member-position">代表取締役</div>
                                </div>
                                <div className="member-text">
                                    リクルートのAIエンジニアを経て、当社共同創業。創業後は営業兼開発として数々の製造現場に足を運びAI/IoTシステムを導入してきた。
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_2">
                                <div className="member-photo bg-img_cover" />
                                <div className="member-lead">
                                    <div className="member-name">内山 祐介</div>
                                    <div className="member-position">取締役 博士(工学)</div>
                                </div>
                                <div className="member-text">
                                    日立製作所機械研究所の研究員を経て、筑波大学院で博士（工学）を取得後、当社共同創業。新製品開発のための実験や解析を担当。
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_3">
                                <div className="member-photo bg-img_cover" />
                                <div className="member-lead">
                                    <div className="member-name">片山 翔太</div>
                                    <div className="member-position">マーケティング</div>
                                </div>
                                <div className="member-text">
                                    リクルートのマーケターを経て、当社入社。顧客・販売パートナーとの関係構築を担当。
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_4">
                                <div className="member-photo bg-img_cover" />
                                <div className="member-lead">
                                    <div className="member-name">岡 宏樹</div>
                                    <div className="member-position">リサーチ</div>
                                </div>
                                <div className="member-text">
                                    富士ゼロックスの設計開発を経て、当社入社。新製品開発のための実験や解析を担当。
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_5">
                                <div className="member-photo bg-img_cover" />
                                <div className="member-lead">
                                    <div className="member-name">酒井 利将</div>
                                    <div className="member-position">セールス</div>
                                </div>
                                <div className="member-text">
                                    DMG森精機のアプリケーションエンジニアを経て、当社入社。工作機械・加工技術における知見を活かして、顧客営業を担当。
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4">
                            <div className="member_single member_more">
                                <div>& MORE</div>
                            </div>
                        </div>
                    </div>{/*.row*/}
                </div>{/*.member-list*/}
              </div>{/*.contents-box*/}
            </div>{/*.member*/}
	
            <div className="history">
              <div className="contents-box">
                <div className="contents-title">HISTORY</div>
                <div className="bg-img_cover" />
                <div className="contents-comment">
                  <table>
                    <tbody><tr><th>2018年6月</th><td>東京都豊島区で⻆屋と内山が株式会社MAZINを創業。製造現場に特化したAI/IoTシステムの製造開発を開始。</td></tr>
                      <tr><th>2018年10月</th><td>独シーメンス社とパートナーシップを締結。IoTクラウドサービス「MindSphere」の提供を開始。</td></tr>
                      <tr><th>2019年3月</th><td>日本物理学会 第74回年次大会に登壇。</td></tr>
                      <tr><td /><td>学術雑誌「Physical Review E」に確率過程の研究結果を発表。</td></tr>
                      <tr><th>2019年5月</th><td>人員増加に伴い東京都台東区に本社を移転。</td></tr>
                      <tr><th>2019年6月</th><td>第21回人工知能学会金融情報学研究会（SIG-FIN）にて優秀賞を受賞。</td></tr>
                      <tr><th>2020年2月</th><td>製造業に特化したIoTデータ基盤構築ソリューションを提供開始。</td></tr>
                      <tr><th>2020年5月</th><td>製造工程別パッケージの開発販売開始。</td></tr>
                      <tr><th>2020年8月</th><td>製造工程 IoTパッケージ「Zシリーズ」が「Tech Factory」に掲載。</td></tr>
                      <tr><td /><td>製造工程IoTパッケージ「Zシリーズ」が「日経XTECH ACTIVE」に掲載。</td></tr>
                      <tr><th>2020年9月</th><td>芝浦工業大学の澤武一教授と加工技術に関する共同研究を開始。</td></tr>
                      <tr><th>2020年11月</th><td>装置メーカー向けAI組込ソリューション提供開始。</td></tr>
                      <tr><th>2020年12月</th><td>東京都産業研究所と射出成形機の IoT化に関する共同研究を開始。</td></tr>
                    </tbody></table>
                </div>{/*.contents-comment*/}
              </div>{/*.contents-box*/}
            </div>{/*.history*/}
            <div className="info">
              <div className="contents-box">
                <div className="contents-title contents-title_white">INFO</div>
                <h3 className="underline">企業情報</h3><div />
                <div className="contents-comment">
                  MAZINは、東京の下町、浅草を拠点に製造現場に特化したAI/IoTシステムの製品開発を行なっています。
                  <table>
                    <tbody><tr><th>会社名</th><td>株式会社MAZIN</td></tr>
                      <tr><th>所在地</th><td>〒111-0035 東京都台東区西浅草3-29-14 アメイジングビル 3F</td></tr>
                      <tr><th>設立</th><td>2018年6月</td></tr>
                      <tr><th>事業内容</th><td>製造現場に特化したAI/IoTシステムの製品開発・販売</td></tr>
                      <tr><th>代表取締役</th><td>角屋貴則</td></tr>
                      <tr><th>電話番号</th><td>03-5981-8078</td></tr>
                      <tr><th>WEBサイト</th><td>https://www.mazin.tech</td></tr>
                    </tbody></table>
                </div>{/*.contents-comment*/}
              </div>{/*.contents-box*/}
            </div>{/*.info*/}
          </div>{/*.contents*/}
        </div>{/*.row*/}
      </div>{/*.container-fluid*/}
	

	  </Layout>
	
 	)
}

export default aboutHtml